import React from "react"
import { graphql } from "gatsby"
import { Flex } from "theme-ui"

import Layout from "../components/layouts"
import { TileImage } from "../components/Page/ContentModules/Tile/elements"
import { NewsletterInput } from "../components/new-ui/newsletter-signup"
import SEO from "../components/seo"

const Subscribe = ({ data }) => {
  const { tileSection } = data

  return (
    <>
      <SEO title="Subscribe" canonicalUrl="/subscribe" breadcrumbsList={
        [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.tekla.com"
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Subscribe",
            item: "https://www.tekla.com/subscribe"
          }]} />
      <Layout isSubscribePage almostWhite>
        {tileSection?.tiles && (
          <Flex
            sx={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: ["column", "row"],
            }}
          >
            {tileSection.tiles?.[0]?.backgroundImage && (
              <Flex sx={{ flex: [null, "0 0 50%"], width: ["100%", "50%"] }}>
                <TileImage
                  image={tileSection.tiles[0].backgroundImage.gatsbyImageData}
                  alt=""
                />
              </Flex>
            )}
            <Flex
              sx={{
                flexDirection: "column",
                flex: "0 0 50%",
                alignItems: "center",
                marginTop: ["32px", "0px"],
                paddingX: ["0px", "8px"],
              }}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: ["flex-start", "center"],
                  minHeight: "350px",
                  maxWidth: "450px",
                  paddingInline: 4,
                }}
              >
                <NewsletterInput
                  showDescription={true}
                  title={tileSection.displayTitle || ""}
                  subtitle={tileSection.displaySubtitle || ""}
                  source="main_subscribe_page"
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Layout>
    </>
  )
}

export default Subscribe

export const query = graphql`
  {
    tileSection: contentfulTileSection(
      title: { eq: "subscribe-tile-section" }
      node_locale: { eq: "en-US" }
    ) {
      title
      displayTitle
      displaySubtitle
      tiles {
        ...Tile
        ...CollectionProduct
      }
      internal {
        type
      }
    }
  }
`
